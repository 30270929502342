<template>
  <div class="magnifier-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>

    <div
      class="game-content"
      :style="{
        backgroundImage:
          'url(' +
          require('@/assets/img/03-Backgrounds/background-practice.svg') +
          ')',
      }"
    >
      <Star :totalStarNumber="optionList.length" :currentIndex="currentIndex" />
      <div class="bg-img-area">
        <img :src="bgImg" alt="" ref="imgBox" />
        <div class="answer-img-area">
          <template v-for="(item, index) in optionList">
            <img
              :src="item.image"
              alt=""
              :key="index"
              v-if="chooseIndexList.indexOf(index + 1) !== -1"
            />
          </template>
        </div>
      </div>
      <div class="right-text" v-if="title">
        <span class="font-text pinyin txt-py font-pinyin-medium">{{
          title.pinyin
        }}</span>
        <span class="txt-hz font-hanzi-medium">{{ title.hanzi }}</span>
      </div>
      <div class="answer-img-area">
        <template v-for="(item, index) in optionList">
          <img
            :src="item.image"
            alt=""
            :key="index"
            v-if="chooseIndexList.indexOf(index + 1) !== -1"
          />
        </template>
      </div>
      <div class="magnifier-area">
        <div
          class="magnifier-wrapper"
          :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
        >
          <div
            class="ckeck-area"
            ref="magniferBox"
            :style="{ left: magniferLeft + 'px', top: magniferTop + 'px' }"
          >
            <img
              :src="bgImg"
              alt=""
              :style="{
                left: bigImgLeft + 'px',
                top: bigImgTop + 'px',
                width: 2 * imgWidth + 'px',
                height: 2 * imgHeight + 'px',
              }"
              ref="bigImgBox"
            />
            <template v-for="(item, index) in optionList">
              <img
                v-if="chooseIndexList.indexOf(index + 1) !== -1"
                :key="index"
                :src="item.image"
                alt=""
                :style="{
                  left: bigImgLeft + 'px',
                  top: bigImgTop + 'px',
                  width: 2 * imgWidth + 'px',
                  height: 2 * imgHeight + 'px',
                }"
              />
            </template>
          </div>
        </div>
        <div class="magnifier-area">
          <div
            class="magnifier-wrapper mask"
            :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
            @mousemove.self="handleMoveMagnifier"
          >
            <div :class="'lesson-' + lessonNo">
              <div
                class="click-area"
                :class="'click-' + (index + 1)"
                v-for="(item, index) in optionList.length"
                :key="index"
                @click.self="handleClickPeople(index + 1)"
                v-show="chooseIndexList.indexOf(index + 1) === -1"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Star from "../Star";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools.js";
export default {
  name: "MagnifierGame",
  props: {
    bgImg: {
      type: String,
      require: true,
    },
    lessonNo: {
      type: Number,
      require: true,
    },
    optionList: {
      type: Array,
      require: true,
    },
    title: {
      type: Object,
      require: true,
    },
  },
  components: {
    Star,
    PageButton,
  },
  data() {
    return {
      magnifierSocketInfo: {},
      clickIndex: 0,
      currentIndex: 0,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },

      imgWidth: null,
      imgHeight: null,
      magniferWidth: null,
      magniferLeft: null,
      magniferTop: null,
      bigImgTop: null,
      bigImgLeft: null,
      isMove: false,
      bigImgWidth: null,
      bigImgHeight: null,
      chooseIndexList: [],
    };
  },
  created() {
    // localStorage.handleClickBasket
  },
  mounted() {
    setTimeout(() => {
      this.adjustSize(this);
    }, 50);
    this.$bus.$on("clickMagnifierArea", ({ index }) => {
      this.handleClickPeople(index, true);
    });
    window.addEventListener("resize", () => {
      this.imgWidth = this.$refs.imgBox.getBoundingClientRect().width;
      this.imgHeight = this.$refs.imgBox.getBoundingClientRect().height;
      this.magniferWidth = this.$refs.magniferBox.getBoundingClientRect().width;
      this.bigImgWidth = this.$refs.bigImgBox.getBoundingClientRect().width;
      this.bigImgHeight = this.$refs.bigImgBox.getBoundingClientRect().height;
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickMagnifierArea");
  },
  watch: {
    magnifierSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20054,
          data: { value },
          text: "MagnifierGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    adjustSize(that) {
      that.imgWidth = that.$refs.imgBox.getBoundingClientRect().width;
      that.imgHeight = that.$refs.imgBox.getBoundingClientRect().height;
      console.log(that.imgWidth);
      that.magniferWidth = that.$refs.magniferBox.getBoundingClientRect().width;
      that.bigImgWidth = that.$refs.bigImgBox.getBoundingClientRect().width;
      that.bigImgHeight = that.$refs.bigImgBox.getBoundingClientRect().height;
    },

    handleMoveMagnifier(e) {
      this.isMove = true;
      this.magniferLeft =
        e.offsetX - this.magniferWidth / 2 < 0
          ? 0
          : e.offsetX - this.magniferWidth / 2;
      this.magniferTop =
        e.offsetY - this.magniferWidth / 2 < 0
          ? 0
          : e.offsetY - this.magniferWidth / 2;
      if (this.magniferLeft >= this.imgWidth - this.magniferWidth) {
        this.magniferLeft = this.imgWidth - this.magniferWidth;
      }
      if (this.magniferTop >= this.imgHeight - this.magniferWidth) {
        this.magniferTop = this.imgHeight - this.magniferWidth;
      }
      this.bigImgLeft = -1 * (2 * this.magniferLeft + this.magniferWidth / 2);
      this.bigImgTop = -1 * (2 * this.magniferTop + this.magniferWidth / 2);
      if (this.magniferLeft === 0) {
        this.bigImgLeft = 0;
      } else if (this.magniferLeft === this.imgWidth - this.magniferWidth) {
        this.bigImgLeft = 2 * this.imgWidth - this.magniferWidth;
      }

      if (this.magniferTop === 0) {
        this.bigImgTop = 0;
      } else if (this.magniferTop === this.imgHeight - this.magniferWidth) {
        this.bigImgTop = 2 * this.imgHeight - this.magniferWidth;
      }
      //   console.log(this.imgWidth, this.bigImgWidth);
    },
    handleClickPeople(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.magnifierSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      console.log(index);
      this.chooseIndexList.push(index);
      playCorrectSound(true, false);
      this.currentIndex++;
      if (this.currentIndex >= this.optionList.length) {
        this.isLastStep = true;
        startConfetti();
        playCorrectSound();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.magnifier-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 48px;
    .right-text {
      background-color: #cd4c3f;
      border-top-right-radius: 29px;
      border-bottom-right-radius: 29px;
      position: absolute;
      top: 6%;
      left: 0;
      color: rgb(255, 255, 255);
      display: flex;
      justify-content: center;
      flex-flow: column;
      z-index: 9;
      width: auto;
      height: auto;
      padding: 3%;
    }
    .bg-img-area {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 90%;
        max-width: 75%;
        // width: 100%;
        // object-fit: cover;
      }
    }
    .answer-img-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 90%;
        max-width: 75%;
      }
    }
    .magnifier-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .magnifier-wrapper {
        // background: red;
        // opacity: 0.1;
        position: relative;
        .ckeck-area {
          box-sizing: border-box;
          position: absolute;
          width: 150px;
          height: 150px;
          background: transparent;
          border-radius: 50%;
          border: 10px solid #224e96;
          overflow: hidden;
          img {
            height: 90%;
            position: absolute;
          }
        }

        .ckeck-area::before {
          //   content: "";
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   width: 20px;
          //   height: 100px;
          //   background: #cd4c3f;
        }
        .click-area {
          // background: #000;
          // opacity: 0.2;
          top: 0;
          left: 0;
          position: absolute;
          width: 5%;
          height: 5%;
          border-radius: 50%;
          cursor: pointer;
        }
        .click-1 {
          top: 46%;
          left: 16%;
        }
        .click-2 {
          top: 68%;
          left: 24%;
        }
        .click-3 {
          top: 46%;
          left: 69%;
        }
        .click-4 {
          top: 76%;
          left: 73%;
        }
        .click-5 {
          top: 14%;
          left: 58%;
        }
        .lesson-11 {
          .click-area {
            // background: #000;
            // opacity:0.5;
            top: 0;
            left: 0;
            position: absolute;
            width: 5%;
            height: 10%;
            border-radius: 0;
            cursor: pointer;
          }
          .click-1 {
            top: 51%;
            left: 67%;
            width: 7%;
          }
          .click-2 {
            top: 25%;
            left: 26%;
          }
          .click-3 {
            top: 51%;
            left: 16%;
          }
          .click-4 {
            top: 70%;
            left: 28%;
          }
          .click-5 {
            top: 15%;
            left: 62%;
          }
          .click-6 {
            top: 63%;
            left: 72%;
          }
          // .click-1 {
          //   top: 52%;
          //   left: 16%;
          // }
          // .click-2 {
          //   top: 72%;
          //   left: 29%;
          // }
          // .click-3 {
          //   top: 51%;
          //   left: 67%;
          //   width: 7%;
          // }
          // .click-4 {
          //   top: 63%;
          //   left: 71%;
          //   width: 9%;
          // }
          // .click-5 {
          //   top: 17%;
          //   left: 62%;
          //   width: 13%;
          //   height: 12%;
          // }
        }
        .lesson-25 {
          .click-area {
            // background: #000;
            // opacity:0.5;
            top: 0;
            left: 0;
            position: absolute;
            width: 5%;
            height: 10%;
            border-radius: 0;
            cursor: pointer;
          }
          .click-1 {
            top: 66%;
            left: 18%;
          }
          .click-2 {
            top: 44%;
            left: 24%;
          }
          .click-3 {
            top: 46%;
            left: 41%;
            width: 7%;
          }
          .click-4 {
            top: 26%;
            left: 79%;
            width: 9%;
          }
          .click-5 {
            top: 60%;
            left: 67%;
            width: 13%;
            height: 12%;
          }
        }
        .lesson-27 {
          .click-area {
            // background: #000;
            // opacity: 0.5;
            top: 0;
            left: 0;
            position: absolute;
            width: 5%;
            height: 10%;
            border-radius: 0;
            cursor: pointer;
          }
          .click-1 {
            top: 23%;
            left: 63%;
            height: 7%;
          }
          .click-2 {
            top: 42%;
            left: 57%;
            width: 5%;
            height: 6%;
          }
          .click-3 {
            top: 69%;
            left: 39%;
            width: 5%;
          }
          .click-4 {
            top: 54%;
            left: 18%;
            width: 3%;
            height: 4%;
          }
          .click-5 {
            top: 21%;
            left: 34%;
            width: 6%;
            height: 11%;
          }
        }

        .lesson-451 {
          .click-area {
            // background: #000;
            // opacity:0.5;
            top: 0;
            left: 0;
            position: absolute;
            width: 5%;
            height: 10%;
            border-radius: 0;
            cursor: pointer;
          }
          .click-1 {
            top: 47%;
            left: 22%;
            width: 5%;
            height: 6%;
          }
          .click-2 {
            top: 67%;
            left: 38%;
            width: 5%;
            height: 6%;
          }
          .click-3 {
            top: 20%;
            left: 59%;
            width: 5%;
            height: 6%;
          }
          .click-4 {
            top: 33%;
            left: 75%;
            width: 6%;
            height: 6%;
          }
          .click-5 {
            top: 62%;
            left: 72%;
            width: 4%;
            height: 5%;
          }
        }

        .lesson-452 {
          .click-area {
            // background: #000;
            // opacity:0.5;
            top: 0;
            left: 0;
            position: absolute;
            width: 5%;
            height: 10%;
            border-radius: 0;
            cursor: pointer;
          }
          .click-1 {
            top: 25%;
            left: 17%;
            width: 5%;
            height: 5%;
          }
          .click-2 {
            top: 31%;
            left: 38%;
            width: 4%;
            height: 5%;
          }
          .click-3 {
            top: 29%;
            left: 73%;
            width: 3%;
            height: 5%;
          }
          .click-4 {
            top: 57%;
            left: 19%;
            width: 5%;
            height: 5%;
          }
          .click-5 {
            top: 57%;
            left: 69%;
            width: 5%;
            height: 5%;
          }
        }
        .lesson-49 {
          .click-area {
            // background: #000;
            // opacity: 0.5;
            top: 0;
            left: 0;
            position: absolute;
            width: 5%;
            height: 10%;
            border-radius: 0;
            cursor: pointer;
          }
          .click-1 {
            top: 27%;
            left: 72%;
            width: 4%;
            height: 4%;
          }
          .click-2 {
            top: 43%;
            left: 58%;
            width: 4%;
            height: 5%;
          }
          .click-3 {
            top: 46%;
            left: 20%;
            width: 7%;
            height: 6%;
          }
          .click-4 {
            top: 18%;
            left: 32%;
            width: 5%;
            height: 5%;
          }
          .click-5 {
            top: 74%;
            left: 15%;
            width: 3%;
            height: 4%;
          }
        }

        .lesson-53 {
          .click-area {
            // background: #000;
            // opacity:0.5;
            top: 0;
            left: 0;
            position: absolute;
            width: 5%;
            height: 10%;
            border-radius: 0;
            cursor: pointer;
          }
          .click-1 {
            top: 21%;
            left: 35%;
            width: 8%;
            height: 8%;
          }
          .click-2 {
            top: 48%;
            left: 12%;
            width: 15%;
            height: 12%;
          }
          .click-3 {
            top: 52%;
            left: 56%;
            width: 5%;
            height: 16%;
          }
          .click-4 {
            top: 28%;
            left: 64%;
            width: 12%;
            height: 6%;
          }
          .click-5 {
            top: 35%;
            left: 26%;
            width: 6%;
            height: 8%;
          }
          .click-6 {
            top: 68%;
            left: 38%;
            width: 9%;
            height: 12%;
          }
        }
      }

      .mask {
        z-index: 100;
        cursor: crosshair;
      }
    }
  }
}
</style>
