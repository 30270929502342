var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "magnifier-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        {
          staticClass: "game-content",
          style: {
            backgroundImage:
              "url(" +
              require("@/assets/img/03-Backgrounds/background-practice.svg") +
              ")",
          },
        },
        [
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.optionList.length,
              currentIndex: _vm.currentIndex,
            },
          }),
          _c("div", { staticClass: "bg-img-area" }, [
            _c("img", { ref: "imgBox", attrs: { src: _vm.bgImg, alt: "" } }),
            _c(
              "div",
              { staticClass: "answer-img-area" },
              [
                _vm._l(_vm.optionList, function (item, index) {
                  return [
                    _vm.chooseIndexList.indexOf(index + 1) !== -1
                      ? _c("img", {
                          key: index,
                          attrs: { src: item.image, alt: "" },
                        })
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]),
          _vm.title
            ? _c("div", { staticClass: "right-text" }, [
                _c(
                  "span",
                  { staticClass: "font-text pinyin txt-py font-pinyin-medium" },
                  [_vm._v(_vm._s(_vm.title.pinyin))]
                ),
                _c("span", { staticClass: "txt-hz font-hanzi-medium" }, [
                  _vm._v(_vm._s(_vm.title.hanzi)),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "answer-img-area" },
            [
              _vm._l(_vm.optionList, function (item, index) {
                return [
                  _vm.chooseIndexList.indexOf(index + 1) !== -1
                    ? _c("img", {
                        key: index,
                        attrs: { src: item.image, alt: "" },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c("div", { staticClass: "magnifier-area" }, [
            _c(
              "div",
              {
                staticClass: "magnifier-wrapper",
                style: {
                  width: _vm.imgWidth + "px",
                  height: _vm.imgHeight + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    ref: "magniferBox",
                    staticClass: "ckeck-area",
                    style: {
                      left: _vm.magniferLeft + "px",
                      top: _vm.magniferTop + "px",
                    },
                  },
                  [
                    _c("img", {
                      ref: "bigImgBox",
                      style: {
                        left: _vm.bigImgLeft + "px",
                        top: _vm.bigImgTop + "px",
                        width: 2 * _vm.imgWidth + "px",
                        height: 2 * _vm.imgHeight + "px",
                      },
                      attrs: { src: _vm.bgImg, alt: "" },
                    }),
                    _vm._l(_vm.optionList, function (item, index) {
                      return [
                        _vm.chooseIndexList.indexOf(index + 1) !== -1
                          ? _c("img", {
                              key: index,
                              style: {
                                left: _vm.bigImgLeft + "px",
                                top: _vm.bigImgTop + "px",
                                width: 2 * _vm.imgWidth + "px",
                                height: 2 * _vm.imgHeight + "px",
                              },
                              attrs: { src: item.image, alt: "" },
                            })
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            ),
            _c("div", { staticClass: "magnifier-area" }, [
              _c(
                "div",
                {
                  staticClass: "magnifier-wrapper mask",
                  style: {
                    width: _vm.imgWidth + "px",
                    height: _vm.imgHeight + "px",
                  },
                  on: {
                    mousemove: function ($event) {
                      if ($event.target !== $event.currentTarget) return null
                      return _vm.handleMoveMagnifier.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { class: "lesson-" + _vm.lessonNo },
                    _vm._l(_vm.optionList.length, function (item, index) {
                      return _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.chooseIndexList.indexOf(index + 1) === -1,
                            expression:
                              "chooseIndexList.indexOf(index + 1) === -1",
                          },
                        ],
                        key: index,
                        staticClass: "click-area",
                        class: "click-" + (index + 1),
                        on: {
                          click: function ($event) {
                            if ($event.target !== $event.currentTarget)
                              return null
                            return _vm.handleClickPeople(index + 1)
                          },
                        },
                      })
                    }),
                    0
                  ),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }