<template>
  <div class="story-page-container layout-border">
    <div
      class="story-page-content"
      :style="{
        'background-image': `url(${bgImg})`,
      }"
    ></div>
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :numBers="numBers"
      @updateNumbers="updateNumbers($event)"
    ></PageButton>
  </div>
</template>
<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  components: {
    PageButton,
  },
  props: {
    bgImg: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      numBers: 1,
      hoverClassOne: 1,
      hoverFontNum: 0,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "play",
            startNumberRange: 1,
            endNumberRange: 1,
            numBerValueAfterClick: null,
          },
        ],
      },
    };
  },
  watch: {
    // 发送信息
    hoverFontNum() {
      const data = {
        clickType: 2000101,
        data: { value: this.hoverClassOne },
        text: "测试StoryHover事件socket",
      };
      this.$bus.$emit("kids_webSocket_sendInfo", data);
    },
  },
  mounted() {
    // 接受信息
    this.$bus.$on("hoverPlay", (val) => {
      this.mouseClick(val, 1);
    });
  },
  beforeDestroy() {
    this.$bus.$off("hoverPlay");
  },
  methods: {
    mouseClick(id, type) {
      if (type != 1) {
        this.hoverFontNum++;
      }
      if (id == 2) {
        this.hoverClassOne = 2;
      } else {
        this.hoverClassOne = 1;
      }
    },
    // mouseover() {
    //   this
    //   this.hoverClassOne = 2;
    // },
    // mouseleave() {
    //   this.hoverClassOne = 1;
    // },
    // todoList: jump to next
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    updateNumbers(value) {
      this.numBers = value;
      console.log(value);
    },
  },
};
</script>
<style scoped lang="scss">
.story-page-container {
  .story-page-content {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-size: cover;
    background-position: center;
    .customlesson-content-img {
      position: absolute;
      bottom: -1.5%;
      right: 0;
      width: 20%;
      img {
        width: 100%;
        cursor: pointer;
      }
    }
  }
}
</style>
